// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aengste-phobien-js": () => import("./../../../src/pages/aengste-phobien.js" /* webpackChunkName: "component---src-pages-aengste-phobien-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gewichtsreduktion-js": () => import("./../../../src/pages/gewichtsreduktion.js" /* webpackChunkName: "component---src-pages-gewichtsreduktion-js" */),
  "component---src-pages-hypnosetherapie-js": () => import("./../../../src/pages/hypnosetherapie.js" /* webpackChunkName: "component---src-pages-hypnosetherapie-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lernschwierigkeiten-js": () => import("./../../../src/pages/lernschwierigkeiten.js" /* webpackChunkName: "component---src-pages-lernschwierigkeiten-js" */),
  "component---src-pages-reinkarnationstherapie-js": () => import("./../../../src/pages/reinkarnationstherapie.js" /* webpackChunkName: "component---src-pages-reinkarnationstherapie-js" */),
  "component---src-pages-relevante-seiten-js": () => import("./../../../src/pages/relevante-seiten.js" /* webpackChunkName: "component---src-pages-relevante-seiten-js" */),
  "component---src-pages-schlafstoerungen-js": () => import("./../../../src/pages/schlafstoerungen.js" /* webpackChunkName: "component---src-pages-schlafstoerungen-js" */),
  "component---src-pages-schmerzen-migraenen-js": () => import("./../../../src/pages/schmerzen-migraenen.js" /* webpackChunkName: "component---src-pages-schmerzen-migraenen-js" */),
  "component---src-pages-selbstbewusstsein-ich-staerkung-js": () => import("./../../../src/pages/selbstbewusstsein-ich-staerkung.js" /* webpackChunkName: "component---src-pages-selbstbewusstsein-ich-staerkung-js" */),
  "component---src-pages-termin-js": () => import("./../../../src/pages/termin.js" /* webpackChunkName: "component---src-pages-termin-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */)
}

